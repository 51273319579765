import styled from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';
import { info, infoBright } from 'constants/theme';
import nanostyled from 'nanostyled';

export const Form = styled.form`
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  span {
    left: 1rem !important;
    transition: font-size 200ms ease 0s, padding 200ms ease 0s,
      margin-top 200ms ease 0s !important;
  }
  input,
  textarea {
    /* font-family: inherit; */
    font-size: inherit;
    border: 1px solid;
    outline: none;
    border-radius: 0;
    padding: 1em;
    background: ${infoBright};
    &::placeholder {
      color: black;
    }
    &:required {
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? '.8' : '0')};
    visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
  }
`;

export const Group = nanostyled('div', {
  base: 'w-full flex flex-wrap',
});

export const Submit = nanostyled('input', {
  base:
    'font-malee w-full appearance-none bg-transparent rounded-none cursor-pointer uppercase hover:bg-info',
});

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  box-sizing: border-box;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  p {
    line-height: 1.6;
    margin: 0;
    margin-bottom: 1.25em;
  }
`;

export const Circle = styled.div`
  background: white;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* max-width: 70vw; */
  /* padding: 5vh 10vw; */
  position: relative;
  max-width: 40rem;
`;

export const Background = styled.div`
  background: rgba(255, 255, 255, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const Button = styled.div`
  font-size: 1em;
  display: inline-block;
  margin: 0 auto;
  border: 1px solid black;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  color: black;
  width: 100%;
  background: #c3ffd3;
  padding: 1em;
  text-decoration: none;
  transition: 0.2s;
  z-index: 99;
  &:focus {
    outline: none;
    background: #deffe7;
  }
  &:hover {
    background: #deffe7;
  }
`;
