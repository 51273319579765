import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import FloatingInput from 'components/floating-input';
import FloatingTextarea from 'components/floating-textarea';
import Type from 'components/type';
import Box from 'components/box';

import {
  Form,
  Modal,
  Button,
  Submit,
  Group,
  Circle,
  Background,
} from './contact-formik.css';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  // handleInputChange = event => {
  //   const target = event.target;
  //   const value = target.value;
  //   const name = target.name;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  sendToNetlify = values => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...values }),
    })
      // .then(this.handleSuccess)
      .catch(error => alert(error));
    // event.preventDefault();
  };

  handleSuccess = (values, { resetForm }) => {
    // console.log('test')
    this.sendToNetlify(values);
    resetForm();
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <Formik
        initialValues={{
          name: '',
          subject: '',
          email: '',
          phone: '',
          message: '',
        }}
        onSubmit={this.handleSuccess}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Your name is required'),
          subject: Yup.string().required('A subject is required'),
          email: Yup.string()
            .email('Your email must be a valid email address')
            .required('Your email address is required'),
          phone: Yup.number().min(6, 'Your phone number is too short'),
          message: Yup.string(),
        })}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          validateForm,
          setFieldTouched,
          handleSubmit,
          resetForm,
        }) => (
          <Form
            name="contact"
            onSubmit={handleSubmit}
            data-netlify="true"
            data-netlify-honeypot="bot"
            // overlay={this.state.showModal}
            // onClick={this.closeModal}
          >
            <Type align="mx-auto -mt-4 mb-6 text-center">
              {touched.name && errors.name && <div>{errors.name}</div>}
              {touched.subject && errors.subject && <div>{errors.subject}</div>}
              {touched.email && errors.email && <div>{errors.email}</div>}
              {touched.phone && errors.phone && <div>{errors.phone}</div>}
              {touched.message && errors.message && <div>{errors.message}</div>}
            </Type>

            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <label>
                Don’t fill this out:{' '}
                <input name="bot" onChange={this.handleInputChange} />
              </label>
            </p>
            <Type>
              <Group>
                <FloatingInput
                  name="name"
                  type="text"
                  pos="left"
                  placeholder="Name and Surname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  required
                />
                <FloatingInput
                  name="subject"
                  type="text"
                  pos="right"
                  placeholder="Subject"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subject}
                  error={errors.subject}
                  touched={touched.subject}
                  required
                />
                <FloatingInput
                  name="email"
                  type="email"
                  pos="left"
                  placeholder="Your Email Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  required
                />
                <FloatingInput
                  name="phone"
                  type="tel"
                  pos="right"
                  placeholder="Your Phone Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={errors.phone}
                  touched={touched.phone}
                />
              </Group>
              <FloatingTextarea
                name="message"
                type="text"
                placeholder="Write Your Message Here"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                error={errors.message}
                touched={touched.message}
                required
              />
              {isValid ? (
                <Submit name="submit" type="submit" value="Send" />
              ) : (
                <Submit
                  name="submit"
                  type="submit"
                  onClick={() =>
                    validateForm().then(errors => {
                      setFieldTouched('name', true);
                      setFieldTouched('subject', true);
                      setFieldTouched('email', true);
                      setFieldTouched('phone', true);
                      setFieldTouched('message', true);
                    })
                  }
                />
              )}
            </Type>

            <Modal visible={this.state.showModal}>
              <Background />
              <Circle>
                <Box width="pt-14 pb-14 px-6 md:px-16 max-w-xl xl:max-w-2xl">
                  <Type align="text-center" size="large">
                    <p>
                      Thank you for reaching out. We will get back to you as
                      soon as possible.
                    </p>
                  </Type>
                  <Button onClick={this.closeModal}>Close</Button>
                </Box>
              </Circle>
            </Modal>
          </Form>
        )}
      />
    );
  }
}

ContactForm.propTypes = {
  data: PropTypes.object,
};

export default ContactForm;
