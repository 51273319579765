import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import slugify from 'slugify';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import PageWrapper from 'components/page-wrapper';
import Box from 'components/box';
import Columns from 'components/columns';
import Divider from 'components/divider';
import Type from 'components/type';
import Head from 'components/head';
import ContactFormik from 'components/contact-formik';

const About = ({ data }) => (
  <PageWrapper bg="bg-info select-infoBright" withAlert>
    <Head pageTitle="About" />
    <Box
      width="max-w-full pt-9 sm:pt-14 px-9 sm:px-14"
      flex="flex flex-col items-center"
    >
      <Box width="pt-14 px-6 md:px-16 max-w-xl xl:max-w-2xl">
        <Type align="text-center" size="large">
          {documentToReactComponents(data.contentfulAboutPage.headline.json)}
        </Type>
        <Divider />
      </Box>
      <Box width="px-6 md:px-16 pb-14 max-w-xl xl:max-w-2xl">
        <Columns>
          {data.contentfulAboutPage.infoSection.map(chunk => (
            <Box width="max-w-full mb-10 bi-avoid" key={chunk.id}>
              <Type as="h3" size="large" spacing="mb-5">
                {chunk.title}
              </Type>
              {/* {chunk.image && (
                <Img className="max-w-sm mb-6" fluid={chunk.image.fluid} />
              )} */}
              <Type>{documentToReactComponents(chunk.content.json)}</Type>
            </Box>
          ))}
        </Columns>
        <Divider spacing="mb-10" />
        <Columns>
          <Type size="large" spacing="mb-14">
            {documentToReactComponents(
              data.contentfulAboutPage.shortAbout.json
            )}
          </Type>
          <div className="bi-avoid">
            <Type as="h3" size="large" spacing="mb-5">
              Requirements
            </Type>
            <Type as="ul" spacing="list-disc ml-5">
              {data.contentfulAboutPage.requirements.map(item => (
                <Type
                  as="li"
                  spacing="pl-2 mb-1 li"
                  key={slugify(item, { lower: true })}
                >
                  {item}
                </Type>
              ))}
            </Type>
          </div>
        </Columns>
        <Divider />
        <Type as="h3" align="text-center" size="large" spacing="mb-10">
          Contributors
        </Type>
        <Columns>
          {data.contentfulAboutPage.team.map(
            ({
              id,
              name,
              nameUrl,
              images,
              description,
              role,
              optionalLeft,
              optionalRight,
            }) => (
              <Box
                width={`max-w-full block mb-8 bi-avoid ${images.length > 1 &&
                  'col-span-all'}`}
                flex="flex flex-col"
                key={id}
                override={
                  id === '03233cbc-a400-5aa9-be13-d4bffe76220b' &&
                  'col-span-all'
                }
              >
                {nameUrl ? (
                  <a
                    href={nameUrl}
                    className="text-center self-center no-underline"
                  >
                    <Type
                      as="h4"
                      size="small-serif"
                      spacing="text-center custom-underline-black hover:custom-underline-none inline"
                    >
                      {name}
                    </Type>
                  </a>
                ) : (
                  <Type
                    as="h4"
                    size="small-serif"
                    spacing="text-center custom-underline-black inline self-center"
                  >
                    {name}
                  </Type>
                )}
                {id === '03233cbc-a400-5aa9-be13-d4bffe76220b' ? (
                  <div className="flex flex-col md:flex-row items-start">
                    {images && images.length > 1 ? (
                      <Columns spacing="md:mt-6">
                        {images.map(({ fluid, id }) => (
                          <Img
                            className="mt-6 w-full md:w-1/2 md:mr-8"
                            key={id}
                            fluid={fluid}
                          />
                        ))}
                      </Columns>
                    ) : (
                      images &&
                      images.map(({ fluid, id }) => (
                        <Img
                          className={`mt-6 w-full md:w-1/2 md:mr-8`}
                          key={id}
                          fluid={fluid}
                        />
                      ))
                    )}
                    <div className={`md:w-1/2 md:ml-8`}>
                      <Type size="small-serif" spacing="mt-8">
                        {role}
                      </Type>
                      <Type spacing="mt-4">
                        {documentToReactComponents(description.json)}
                      </Type>
                      {images &&
                        images.length > 1 &&
                        optionalLeft &&
                        optionalRight && (
                          <Type spacing="">
                            <Columns>
                              <div className="bi-avoid">
                                {documentToReactComponents(optionalLeft.json)}
                              </div>
                              <div className="bi-avoid">
                                {documentToReactComponents(optionalRight.json)}
                              </div>
                            </Columns>
                          </Type>
                        )}
                    </div>
                  </div>
                ) : (
                  <div>
                    {images && images.length > 1 ? (
                      <Columns spacing="md:mt-6">
                        {images.map(({ fluid, id }) => (
                          <Img
                            className="mt-6 md:mt-0"
                            key={id}
                            fluid={fluid}
                          />
                        ))}
                      </Columns>
                    ) : (
                      images &&
                      images.map(({ fluid, id }) => (
                        <Img className="mt-6" key={id} fluid={fluid} />
                      ))
                    )}
                    <Type size="small-serif" spacing="mt-8">
                      {role}
                    </Type>
                    <Type spacing="mt-4">
                      {documentToReactComponents(description.json)}
                    </Type>
                    {images &&
                      images.length > 1 &&
                      optionalLeft &&
                      optionalRight && (
                        <Type spacing="">
                          <Columns>
                            <div className="bi-avoid">
                              {documentToReactComponents(optionalLeft.json)}
                            </div>
                            <div className="bi-avoid">
                              {documentToReactComponents(optionalRight.json)}
                            </div>
                          </Columns>
                        </Type>
                      )}
                  </div>
                )}
              </Box>
            )
          )}
        </Columns>
      </Box>
      <Box id="contact" width="pt-14 px-6 md:px-16 pb-14 max-w-xl xl:max-w-2xl">
        <Divider />
        <Type align="text-center mb-14" size="large">
          {documentToReactComponents(data.contentfulContactPage.headline.json)}
        </Type>
        <ContactFormik />
      </Box>
    </Box>
  </PageWrapper>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    contentfulContactPage {
      id
      headline {
        id
        json
      }
    }
    contentfulAboutPage {
      id
      headline {
        json
      }
      infoSection {
        id
        title
        content {
          id
          json
        }
      }
      shortAbout {
        json
      }
      requirements
      team {
        id
        name
        nameUrl
        role
        images {
          id
          fluid(maxWidth: 1280) {
            ...GatsbyContentfulFluid
          }
        }
        description {
          id
          json
        }
        optionalLeft {
          id
          json
        }
        optionalRight {
          id
          json
        }
      }
    }
  }
`;
